<template>
  <main>
    <div class="sweepstakes_wrap">
    <div class="standard_top">
      <img style="width:100%" src="https://yingzegbw.oss-cn-beijing.aliyuncs.com/common/cerDown.png" alt="">
    </div>
    <div>
        <ui-input
                class="standard_text stand_num"
                name="productNum"
                placeholder="请输入【产品编号】"
                :validate="true"
                labelWidth="120px"
                v-model="productNum"
        ></ui-input>
        <ui-input
                class="standard_text stand_time"
                name="batchNum"
                placeholder="请输入【标签批号】"
                :validate="true"
                labelWidth="120px"
                v-model="batchNum"
        ></ui-input>
    </div>

    <p class="stand_p">同时输入标签批号、产品编码查询证书，可以查询并下载标准物质的证书。</p>
    </div>
    <ui-button class="stan_but" @handleclick="onSubmit" type="submit">查询</ui-button>


  <div class="standard_bottom" v-show="isHide">
    <p>下载证书： <img class="stan_img" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/ee08acacf6688d0a6f26dabf82352fce.png" alt=""><span class="stan_pici" @click="downLoadCert(fileUrl,fileName)">{{titleName}}：{{catNo}}</span></p>
  </div>
  </main>
  <loading :options="loadOption" v-if="loading"></loading>



</template>
<script lang="ts">
import { defineComponent, ref, inject } from "vue";
import UiInput from "@/components/UI/Input";
import UiCode from "@/components/UI/Code";
import UiButton from "@/components/UI/Button";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import qs from "qs";
import { useRouter } from "vue-router";
export default defineComponent({
    name: "ChangeMobile",
    components: {
        UiInput,
      // eslint-disable-next-line vue/no-unused-components
        UiCode,
        Loading,
        UiButton,
    },

    setup() {
        const productNum = ref("");
        const batchNum = ref("");
        const isHide = ref(false);
        const isWyHide = ref(false);
        const loading = ref(false);
        const fileUrl = ref("");
        const fileName = ref("");
        const catNo = ref("");
        const batchNo = ref("");
        const titleName = ref("");
      const loadOption = {
        text: "正在生成证书...",
        color: "#b9135a",
        textColor: "#b9135a",
      };

        //校验产品编号
        function checkProductNum(productNum: string) {
            const reg = /^[A-Z][A-Z()0-9_-]{4,15}$/;
            // console.log("productNum:",productNum);
            if (!productNum || !reg.test(productNum)){
                Toast({
                    type: "warning",
                    title: "请输入正确的产品编号",
                });
                return false;
            }
            return true;
        }
        //校验批次号
        function checkBatchNum(batchNum: string) {
            if(!batchNum||batchNum==""){
                Toast({
                    type: "warning",
                    title: "请输入产品的批次号",
                });
                return false;
            }
            const reg = /^[0-9]{8}$/;
            const reg2 = /^[0-9]{10}$/;
            // console.log("batchNum:",batchNum);
            if (batchNum && !(reg.test(batchNum)||reg2.test(batchNum))) {
                Toast({
                    type: "warning",
                    title: "请输入正确的标签批号",
                });
                return false;
            }
            return true;
        }

        function checkYear(year: string) {
            // console.log("年份：",year);
            if (isNaN(parseInt(year))) {
                Toast({
                    type: "warning",
                    title: "标签批号年份输入有误,请重新输入！",
                });
                loading.value=false;
                return false;
            }
            const now = new Date();
            const currentYear = now.getFullYear();
            const lastFiveYear = currentYear-5;
            // console.log(lastFiveYear+"--"+currentYear);
            if (parseInt(year) < lastFiveYear || parseInt(year) > currentYear) {
                Toast({
                    type: "warning",
                    title: "标签批号年份应该在"+lastFiveYear+"-"+currentYear+"之间！",
                });
                loading.value=false;
                return false;
            }
            return true;
        }

        function checkMonth(month: string) {
            if (isNaN(parseInt(month, 10))) {
                Toast({
                    type: "warning",
                    title: "标签批号月份输入有误,请重新输入！",
                });
                loading.value=false;
                return false;
            } else if (parseInt(month, 10) < 1 || parseInt(month, 10) > 12) {
                Toast({
                    type: "warning",
                    title: "标签批号月份应该在1-12之间！",
                });
                loading.value=false;
                return false;
            }
            return true;
        }

        function checkDay(day: string) {
            if (isNaN(parseInt(day, 10))) {
                Toast({
                    type: "warning",
                    title: "标签批号日期输入有误,请重新输入！",
                });
                loading.value=false;
                return false;
            } else if (parseInt(day, 10) < 1 || parseInt(day, 10) > 31) {
                Toast({
                    type: "warning",
                    title: "标签批号日期应该在1-31之间！",
                });
                loading.value=false;
                return false;
            }
            return true;
        }

        function checkPlanNo(planNo: string) {
            // console.log("计划号：",planNo);
            if(isNaN(parseInt(planNo))){
                Toast({
                    type: "warning",
                    title: "标签批号输入有误,请重新输入！",
                });
                loading.value=false;
                return false;
            }
            return true;
        }
        function downloadCert(productNum: string,batchNum: string) {
            const data = qs.stringify({
                productNum: productNum,
                batchNum: batchNum,
            });
            axios.post("/M/Product/checkProductCert", data)
                .then((res) => {
                    if (res.data.success) {
                        loading.value=false
                        // console.log("res:",res)
                        // console.log(isHide.value,123)
                        catNo.value = res.data.data.catno;
                        fileUrl.value = res.data.link;
                        fileName.value = res.data.fileName;
                        isHide.value = true;
                        if(res.data.code==1){
                            titleName.value = "标签批号";
                            catNo.value = res.data.data.batchNo;
                        }else{
                            titleName.value = "产品编号";
                        }
                        Toast({
                            type: "success",
                            title: "请点击下方链接下载证书",
                            // duration:2000
                        });
                    } else {
                        loading.value=false
                        Toast({
                            type: "warning",
                            title: res.data.msg,
                        });
                    }
                })
                .catch((err) => {
                    Toast({
                        type: "warning",
                        title: err.message || err
                    });
                    loading.value=false;
                });
        }
        function onSubmit() {
            //参数校验
            if (!checkProductNum(productNum.value) || !checkBatchNum(batchNum.value)) {
                return false;
            }
            loading.value = true;
            isHide.value = false;

            const years = batchNum.value.substring(0, 4);
            const moneys = batchNum.value.substring(4, 6);
            const days = batchNum.value.substring(6, 8);
            if (batchNum.value.length==10&&checkYear(years) && checkMonth(moneys) && checkDay(days) && checkPlanNo(batchNum.value.substring(8, 10))) {
                //下载证书
                downloadCert(productNum.value,batchNum.value);
            }
            if (batchNum.value.length==8&&checkYear(years) && checkMonth(moneys) && checkDay(days)) {
                //下载证书
                downloadCert(productNum.value,batchNum.value);
            }
        }

        return {
            productNum,
            batchNum,
            isHide,
            isWyHide,
            fileUrl,
            fileName,
            catNo,
            batchNo,
            titleName,
            onSubmit,
            downloadCert,
            loading,
          loadOption,
        };
    },
    methods:{
        downLoadCert(url: string,name: string){
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            link.click();
        },
        downLoadWyCert(pNum: string,bNum: string){
            if (!pNum|| !bNum) {
                return false;
            }
            /*const data = qs.stringify({
                productNum: pNum,
                batchNum: bNum,
            });*/
            axios.get("/M/Product/certPdfDownLod?productNum="+pNum+"&batchNum="+bNum, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data],{type:"application/pdf"});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', pNum+'.pdf');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    console.error(error);
                });

        }
    },
});

</script>




<style lang="scss" scoped>
  .standard_top{
    height: auto;
  }
  .standard_text{
    width:90%;
    height: 40px;
    margin-left: 5%;
    margin-top: 15%;
    padding-left: 12%;
    line-height: 40px;
    color:#c6c6c9;
  }
  .stand_num{
    background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/88740f1081dbafca3a96033fcee5f284.png");
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 10px;
    border:1px solid #ddd;
  }
  .stand_time{
    background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/faf5d4296f320d17cf703f7a5b0fdfbd.png");
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 10px 6px;
    border:1px solid #ddd;
    margin-top:35px;
  }
  .stand_p{
    width:90%;
    margin-left: 5%;
    margin-top:8%;
    font-size: 14px;
    color:#c6c6c9;
  }
  .stan_but{
    width:90%;
    height: 45px;
    margin-left: 5%;
    background: #b9135a;
    color:#fff;
    text-align: center;
    //line-height: 45px;
    border:none;
    margin-top: 10%;
    font-size: 16px;
  }
  .true_but{
    width:90%;
    height: 45px;
    margin-left: 5%;
    background: #919191;
    color:#fff;
    text-align: center;
    line-height: 45px;
    border:none;
    margin-top: 10%;
    font-size: 16px;
  }
  .standard_bottom{
    width:90%;
    height: 60px;
    margin-left: 5%;
    margin-top: 8%;
    font-size: 16px;
    position: relative;
  }
  .standard_bottom p{
    padding-left: 5%;
    height: 50px;
    line-height: 50px;
    color: #c6c6c9;
  }
  .stan_img{
    display: inline-block;
    width:25px;
    height: 25px;
    position: absolute;
    top:20%;
  }
  .stan_pici{
    width:180px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    margin-left: 38px;
    color:#0008a8;
    text-decoration:underline

  }
</style>
